import { template as template_65a0dcf8d37e40189b862788e20ba7a0 } from "@ember/template-compiler";
const FKText = template_65a0dcf8d37e40189b862788e20ba7a0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
