import { template as template_1666417eabe64e009b075c71cbe9b1ff } from "@ember/template-compiler";
const FKLabel = template_1666417eabe64e009b075c71cbe9b1ff(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
