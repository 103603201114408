import { template as template_38d1f7a923a84fd68163505f3d6ff8c3 } from "@ember/template-compiler";
const WelcomeHeader = template_38d1f7a923a84fd68163505f3d6ff8c3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
